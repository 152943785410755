import React from "react"

import Layout from "components/Layout"
import PageHeader from "components/PageHeader"
import Confirmation from "page_components/contact/Confirmation"

const ContactConfirmation = () => {
  return (
    <Layout seo={{ title: "Dziękujemy" }}>
      <PageHeader title="Dziękujemy" />
      <Confirmation />
    </Layout>
  )
}

export default ContactConfirmation
