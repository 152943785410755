import "./styles.scss"

import React from "react"

const PageHeader = ({ title, type, color }) => {
  return (
    <div
      className={`page-header page-header--${title
        .toLowerCase()
        .replace(" ", "-")}${type ? ` page-header--${type}` : ""}${
        color ? ` page-header--${color}` : ""
      }`}
    >
      {type === "sub" ? <h2>{title}</h2> : <h1>{title}</h1>}
    </div>
  )
}

export default PageHeader
