import "./styles.scss"

import React from "react"

const Confirmation = () => {
  return (
    <section className="contact-confirmation">
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-md-10">
            <h3>Dziękujemy za wysłanie wiadomości.</h3>
            <p>
              Jeden z naszych doradców skontaktuje sięz Tobą najpóźniej w ciągu
              24 godzin.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Confirmation
